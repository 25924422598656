import jQuery from 'jquery';

// eslint-disable-next-line
import 'expose-loader?Popper!popper.js';
import 'bootstrap';
import 'bootstrap-touchspin';

import EventEmitter from 'events';
import prestashop from 'prestashop';

import './prestashop/responsive';
import './prestashop/checkout';
import './prestashop/customer';
import './prestashop/listing';
import './prestashop/product';
import './prestashop/cart';

import DropDown from './components/drop-down';
import Form from './components/form';
import ProductMinitature from './components/product-miniature';
import ProductSelect from './components/product-select';
import TopMenu from './components/top-menu';
import './components/block-cart';

import './components/featuredproducts';
import './components/tophomeproducts';

import './lib/bootstrap-filestyle.min';
import './lib/jquery.scrollbox.min';

import '../scss/theme.scss';

import loadModule from './utils/load-module';

// "inherit" EventEmitter
Object.keys(EventEmitter.prototype).forEach((i) => {
  prestashop[i] = EventEmitter.prototype[i];
});

jQuery(document).ready(() => {
  const dropDownEl = jQuery('.js-dropdown');
  const form = new Form();
  const topMenuEl = jQuery('#top-menu');
  const dropDown = new DropDown(dropDownEl);
  const topMenu = new TopMenu(topMenuEl);
  const productMinitature = new ProductMinitature();
  const productSelect = new ProductSelect();

  dropDown.init();
  form.init();
  topMenu.init();
  productMinitature.init();
  productSelect.init();
});

loadModule('quantity-listing', true);

(function ($) {
  // Load modules chunks asynchronously
  $('[data-module]').each((i, el) => {
    const module = $(el).data('module');

    if (module) {
      loadModule(module, true);
    }
  });
}(jQuery));

var moduleStates = {
    LOAD: 1,
    RUN: 2,
};
var modules = {};

export default function loadModule(moduleName, call) {
    if (!modules[moduleName]) {
        modules[moduleName] = moduleStates.LOAD;
    }

    var runModule = function(module) {
        var moduleFn = module && module.default;

        if (moduleFn) {
            moduleFn(modules[moduleName] === moduleStates.LOAD);
            modules[moduleName] = moduleStates.RUN;
        }
    };

    var load = function() {
        return import('../modules/' + moduleName)
            .then(runModule, runModule)
        ;
    };

    if (call) {
        load();
    } else {
        return load;
    }
}

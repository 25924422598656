import $ from 'jquery';

const $carousel = $('#featured-carousel');

$(document).ready(() => {
  if ($carousel.length) {
    const $inner = $carousel.find('.carousel-inner');
    const totalItems = $carousel.find('.carousel-item').length;

    $carousel.on('slide.bs.carousel', (e) => {
      const $el = $(e.relatedTarget);
      const idx = $el.index();
      const itemsPerSlide = 5;

      if (idx >= totalItems - (itemsPerSlide - 1)) {
        const it = itemsPerSlide - (totalItems - idx);
        for (let i = 0; i < it; i += 1) {
          if (e.direction === 'left') {
            $carousel.find('.carousel-item').eq(i).appendTo($inner);
          } else {
            $carousel.find('.carousel-item').eq(0).appendTo($inner);
          }
        }
      }
    });
  }
});

import $ from 'jquery';

const $carousel = $('#tophomeproducts-carousel');
const MIN_WIDTH = 1024;

let currentWidth = window.innerWidth;
let activeCarousel = currentWidth < MIN_WIDTH;

function removeCarousel() {
  const carousel = $carousel.data('bs.carousel');

  if (carousel) {
    carousel.dispose();
  }
}

function enableCarousel() {
  const carousel = $carousel.data('bs.carousel');

  if (!carousel) {
    $carousel.carousel({
      ride: 'carousel',
    });
  }
}

function checkCarousel() {
  const cw = currentWidth;
  const mw = MIN_WIDTH;
  const w = window.innerWidth;
  const toggle = (cw >= mw && w < mw) || (cw < mw && w >= mw);
  currentWidth = w;
  activeCarousel = currentWidth < mw;

  if (toggle) {
    if (activeCarousel) {
      enableCarousel();
    } else {
      removeCarousel();
    }
  }
}

$(document).ready(() => {
  if ($carousel.length) {
    if (activeCarousel) {
      enableCarousel();
    }

    $(window).on('resize', checkCarousel);
  }
});
